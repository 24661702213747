import BSCSwapAgentABI from "./BSCSwapAgent_ABI.json";
import EthSwapAgentABI from "./EthSwapAgent_ABI.json";
import IBEP20ABI from "./IBEP20_ABI.json";
export const fiero_RPC = "https://rpc2.fieroscan.com";
const base = "https://nodenexarise-p2ebridge.mobiloitte.io";
export const apiURL = `${base}/api/v1/block`;
export const ETHswap = "0x08b7A2a5B3864D74224583D2d01882ae7956277d"; //fiero_swap_address old
export const EthereumChainId = 11155111;
export const BinanceChainId = 97;
export const AvalancheChainId = 43113;
export const MaticChainId = 80001;
export const FieroChainId = 1001;

export const wFiero = "0xad2c17a5402073000fc93A5c73903b7D91EED750";

//RPC details of environment networks
export const getRPCDetails = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return {
        url: "https://ethereum-sepolia-rpc.publicnode.com",
        block_url: "https://sepolia.etherscan.io/",
        chainId_hex: "0xAA36A7",
        name: "SepoliaETH",
        symbol: "SepoliaETH",
        network_name: "Sepolia test network",
      };
    case BinanceChainId:
      return {
        url: "https://bsc-testnet-rpc.publicnode.com",
        block_url: "https://testnet.bscscan.com",
        chainId_hex: "0x61",
        name: "BNB",
        symbol: "BNB",
        network_name: "Binance Smart Chain Testnet",
      };
    case AvalancheChainId:
      return {
        url: "https://api.avax-test.network/ext/bc/C/rpc",
        block_url: "https://testnet.snowtrace.io",
        chainId_hex: "0xA869",
        name: "AVAX",
        symbol: "AVAX",
        network_name: "Avalanche Testnet",
      };
    case MaticChainId:
      return {
        url: "https://polygon-mumbai-bor-rpc.publicnode.com",
        block_url: "https://mumbai.polygonscan.com",
        chainId_hex: "0x13881",
        name: "MATIC",
        symbol: "MATIC",
        network_name: "Mumbai",
      };
    case FieroChainId:
      return {
        url: "https://rpc2.fieroscan.com",
        block_url: "https://fieroscan.com",
        chainId_hex: "0x3E9",
        name: "FIERES",
        symbol: "FIERO",
        network_name: "Fieres Mainnet",
      };
  }
};


//Token swap agent addresses
export const getCurrentSwapAddress = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return "0xa13C5794689f92feE57213f7041295210d0039AD"; 
    case BinanceChainId:
      return "0xa13C5794689f92feE57213f7041295210d0039AD";
    case AvalancheChainId:
      return "0xBa0e5551c517B43c24b9dff149C31A9Af7bA51B9";  // still need to change
    case MaticChainId:
      return "0xa13C5794689f92feE57213f7041295210d0039AD";
  }
};

//Coin swap agent addresses
export const getCoinSwapAddress = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return "0xa13C5794689f92feE57213f7041295210d0039AD";
    case BinanceChainId:
      return "0x1c004b8253A5A92E429Fb52F75f0549AD03881Fb";
    case AvalancheChainId:
      return "0x57F32d38fd099E1752e5D3ad52ea10a56eD5e502";   // still need to change
    case MaticChainId:
      return "0xAAD10eA1aFdCb157FEcd08244dB68A9F8D5C943F";
  }
};

//Token Basic Details
export const getCoinBasicDetails = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return {
        name: "Ethereum Mainnet",
        symbol: "ETH",
        image: "/images/coins/eth.png",
        token: "Ethereum ERC20",
        chain: EthereumChainId,
      };
    case BinanceChainId:
      return {
        name: "Binance Mainnet",
        symbol: "BNB",
        image: "/images/coins/bnb.png",
        token: "Binance BEP20",
        chain: BinanceChainId,
      };
    case AvalancheChainId:
      return {
        name: "Avalanche Mainnet",
        symbol: "AVAX",
        image: "/images/coins/avax-logo.png",
        token: "Avalanche ERC20",
        chain: AvalancheChainId,
      };
    case MaticChainId:
      return {
        name: "Polygon Mainnet",
        symbol: "MATIC",
        image: "/images/coins/polygon-matic-logo.png",
        token: "Polygon ERC20",
        chain: MaticChainId,
      };
    case FieroChainId:
      return {
        name: "Fiero Mainnet",
        symbol: "FIERO",
        image: "/images/coins/fiero.png",
        token: "FIERES ERC20",
        chain: FieroChainId,
      };
  }
};

export const NetworkDetailsList = [
  {
    name: "Ethereum Mainnet",
    symbol: "ETH",
    image: "/images/coins/eth.png",
    token: "Ethereum ERC20",
    chain: EthereumChainId,
  },
  {
    name: "Polygon Mainnet",
    symbol: "MATIC",
    image: "/images/coins/polygon-matic-logo.png",
    token: "Polygon ERC20",
    chain: MaticChainId,
  },
  {
    name: "Avalanche Mainnet",
    symbol: "AVAX",
    image: "/images/coins/avax-logo.png",
    token: "Avalanche ERC20",
    chain: AvalancheChainId,
  },
  {
    name: "Binance Mainnet",
    symbol: "BNB",
    image: "/images/coins/bnb.png",
    token: "Binance BEP20",
    chain: BinanceChainId,
  },
];

export const BSCSwapAgentABIData = BSCSwapAgentABI;
export const EthSwapAgentABIData = EthSwapAgentABI;
export const IBEP20ABIData = IBEP20ABI;
export function generateUniqueAvatar(value) {
  return `https://avatars.dicebear.com/api/identicon/${value}.svg`;
}
