import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import { Avatar, Container, Divider, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import { setCryptoDecimals, sortAddress } from "../hooks/operations";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  getCoinBasicDetails,
  generateUniqueAvatar,
  getRPCDetails,
} from "../Utils";
import NetworkModule from "./NetworkModule";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Web3 from "web3";
import HistoryModal from "./HistoryModal";
const useStyles = makeStyles((theme) => ({
  connect: {
    background:
      "linear-gradient(92.79deg, #FFB000 -32.2%, #FF564D 5.51%, #FF0098 54.01%, #5D00C1 110.93%)",
    filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
    color: "#fff",
    borderRadius: "50px",
    whiteSpace: "pre",
    fontSize: "14px",
    lineHeight: " 21px",
    padding: "12px 35px",
    fontWeight: "500",
    // border:"1px solid transparent",

    "&:hover": {
      color: "#ffffff",
      boxShadow:
        "0 1px 0 0 #fe5aeb, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #fe5aeb, -1px 0 0 0 rgb(254 90 235), 1px -1px 0 0 #f4a91b, -1px 1px 0 0 rgb(254 90 235), 1px 1px 0 0 rgb(254 90 235), -1px -1px 0 0 rgb(244 168 26)",
      backgroundColor: "transparent",
      background: "transparent",
    },
  },
  dialog: {
    // minWidth: 150,
    // position: "fixed",
    // top: 20,
    // right: "16%",
    // background: "#71799c6e",
    background: "#0a0a01",
    borderRadius: "10px",
    maxWidth: "450px",

    "& .newModalBorder": {
      background:
        "linear-gradient(312.78deg, #ffb000 -25.59%, #ff564d 12.16%, #ff0098 39.51%, #5d00c1 55.74%)",
      borderRadius: "10px",
      padding: "2px !important",
    },
    "& .mainbox1": {
      background: "#0a0a01",
      borderRadius: "10px",
      padding: "16px",
    },
  },
  networkButton: {
    fontSize: "14px",
    background: "#101010",
    borderRadius: "30px",
    fontWeight: "400",
    padding: "11px 13px",
    height: "48px",
    "&:hover": {
      background:
        "linear-gradient(92.79deg, #FFB000 -32.2%, #FF564D 5.51%, #FF0098 54.01%, #5D00C1 110.93%)",
    },
  },
  accountBox: {
    display: "flex",
    alignItems: "center",
    borderRadius: "30px",
    cursor: "pointer",
    // margin: "0px 10px",
    "& .leftBox": {
      background: "#101010",
      padding: "16px 10px",
      borderBottomLeftRadius: "30px",
      borderTopLeftRadius: "30px",
      "& h4": {
        fontSize: "14px",
        fontWeight: "400",
        color: "#fff",
      },
    },
    "& .rightBox": {
      display: "flex",
      alignItems: "center",
      background:
        "linear-gradient(95.52deg, #FF6F37 2.05%, #FF2A72 26.57%, #C406A2 53.23%, #8601B7 97.46%)",
      padding: "2px 6px",
      borderBottomRightRadius: "30px",
      borderTopRightRadius: "30px",
      height: "44px",
      "& h4": {
        fontSize: "14px",
        marginRight: "10px",
        color: "#fff",
      },
    },
  },
  desktopView: {
    display: "flex",
    alignItems: "center",
    // "@media(max-width:603px)": {
    //   display: "none",
    // },
  },
  phoneopView: {
    "& .MuiDrawer-paper": {
      background: "#000",
    },
  },
  mobileView: {
    display: "none",
    "@media(max-width:603px)": {
      display: "block",
    },
  },
}));

const Header = ({
  account,
  connect,
  chainId,
  isSwaped,
  setSelectedChain,
  mobileView,
  setState,
  drawerOpen,
  setTabView,
  tabView,
  tranasctionData,
}) => {
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openSelectNetwork, setOpenSelectNetwork] = useState(false);
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  const getUserbalce = async () => {
    const url = getRPCDetails(chainId);
    const web3 = new Web3(new Web3.providers.HttpProvider(url.url));
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(setCryptoDecimals(balanceImETH));
  };
  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, chainId]);
  const logoutHandler = () => {
    window.sessionStorage.removeItem("account");
    window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  const menuData = (
    <>
      <Button
        className={classes.networkButton}
        onClick={() => {
          if (account !== null && isSwaped) {
            setOpenSelectNetwork(true);
          }
        }}
        fullWidth={mobileView ? true : false}
        style={mobileView ? { marginBottom: "10px" } : {}}
      >
        <img
          width="30px"
          src={
            account !== null
              ? getCoinBasicDetails(parseInt(chainId))?.image
              : "/images/coins/fiero.png"
          }
          alt=""
          style={{ marginRight: "5px" }}
        />
        {account !== null
          ? getCoinBasicDetails(parseInt(chainId))?.name
          : "Fiero"}
      </Button>
      {account !== null && (
        <Box
          className={classes.accountBox}
          onClick={() => setOpenHistory(true)}
          style={mobileView ? { margin: "10px 0px" } : { margin: "0px 10px" }}
        >
          <Box className="leftBox">
            <Typography variant="h4">{`${yourWalletBalance} ${
              getCoinBasicDetails(chainId)?.symbol
            }`}</Typography>
          </Box>
          <Box className="rightBox">
            <Typography variant="h4">{sortAddress(account)}</Typography>
            <Avatar
              src={account !== null && generateUniqueAvatar(account)}
              style={{ background: "rgba(255, 255, 255, 0.25)" }}
            />
          </Box>
        </Box>
      )}
      {mobileView && (
        <Box mb={2}>
          {/* <Box style={{ height: "2px", color: "#fff", width: "100%" }}></Box> */}
          <Button
            className={classes.networkButton}
            fullWidth
            onClick={() => {
              setTabView("Bridge");
              handleDrawerClose();
            }}
            style={{ marginBottom: "10px" }}
          >
            Bridge
          </Button>
          <Button
            className={classes.networkButton}
            fullWidth
            onClick={() => window.open("https://fieroscan.com")}
            style={{ marginBottom: "10px" }}
          >
            Explore
          </Button>
          <Button
            className={classes.networkButton}
            fullWidth
            onClick={() => {
              setTabView("Support");
              handleDrawerClose();
            }}
          >
            Support
          </Button>
        </Box>
      )}
      {account === null ? (
        <Button
          variant="outlined"
          className={classes.connect}
          onClick={connect}
          fullWidth={mobileView ? true : false}
          style={{ marginLeft: "5px" }}
        >
          Connect Wallet
        </Button>
      ) : (
        <Button
          className={classes.connect}
          // style={{ borderRadius: 90, paddingLeft: 20, paddingRight: 20 }}
          onClick={handleClickOpen}
          fullWidth={mobileView ? true : false}
        >
          Disconnect
        </Button>
      )}
    </>
  );
  return (
    <>
      <Toolbar
        style={{
          backgroundColor: "rgb(12, 10, 12)",
          padding: "10px 0px",
          position: "fixed",
          width: "100%",
          zIndex: "1",
        }}
      >
        <Container
          maxWidth="lg"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Logo mobileView={mobileView} />
          {!mobileView && <Box className={classes.desktopView}>{menuData}</Box>}
          {mobileView && (
            <Box
            //  className={classes.mobileView}
            >
              <IconButton onClick={handleDrawerOpen}>
                <MenuIcon
                  style={{ color: "rgb(255, 38, 118)", fontSize: "30px" }}
                />
              </IconButton>
            </Box>
          )}

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
            classes={{
              paper: classes.dialog,
            }}
            // style={{ minWidth: 150, position: 'absolute', top: 20, right: '25%' }}
          >
            <Box className="newModalBorder">
              <Box className="mainbox1">
                <DialogContent style={{ padding: 5 }}>
                  <Box align="center">
                    <Typography
                      variant="h4"
                      style={{
                        fontFamily: "Sora, sans-serif",
                        fontWeight: "300",
                        marginBottom: "16px",
                        color: "#fff",
                      }}
                    >
                      Log Out
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        fontFamily: "'Sora', sans-serif",
                        color: "#fff",
                      }}
                    >
                      Are you sure want to disconnect?
                    </Typography>
                    <Box
                      align="center"
                      mt={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {/* <Button
                        fullWidth
                        onClick={handleClose}
                        style={{ marginRight: "5px" }}
                      >
                        No
                      </Button> */}
                      <Button
                        className={classes.connect}
                        // style={{ borderRadius: 90, paddingLeft: 20, paddingRight: 20 }}
                        onClick={handleClose}
                      >
                        No
                      </Button>{" "}
                      &nbsp; &nbsp;
                      <Button
                        onClick={logoutHandler}
                        className={classes.connect}
                        style={{ background: "#0e0d0e" }}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </DialogContent>
              </Box>
            </Box>
          </Dialog>
        </Container>
      </Toolbar>
      <NetworkModule
        open={openSelectNetwork}
        setSelectedChain={(item) => setSelectedChain(item)}
        close={() => setOpenSelectNetwork(false)}
      />
      <HistoryModal
        account={account}
        open={openHistory}
        tranasctionData={tranasctionData}
        close={() => setOpenHistory(false)}
      />
      {mobileView && (
        <Drawer
          anchor="right"
          className={classes.phoneopView}
          open={drawerOpen}
          onClose={handleDrawerClose}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px 20px",
              justifyContent: "flex-start",
            }}
          >
            {" "}
            <Logo />
            <Box mt={5}>{menuData}</Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};

export default Header;
